import React from 'react';
import './CARD.css';

export default function SCard({ title, price, link }) {
    const formatPrice = (price) => {
        return price % 1 === 0 ? price.toFixed(0) : price.toFixed(2);
    };

    return (
        <div className="card-container m-3">
            <div className="card shadow-lg">
                <div className="card-body">
                    <div className="card-img-top">
                        <h3 className="card-title text-shadow-custom" style={{ textAlign: "center", fontWeight: "700" }}>{title}</h3>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <span style={{ fontWeight: "bold", fontSize: "24px", marginBottom: "10px" }}>
                            &#163; {formatPrice(price)}
                        </span>
                        <a href={link} className="btn btn-dark custom-button" style={{ borderRadius: "50px", textAlign: "center" }}>
                            Book Now &nbsp;<strong>&#10148;</strong>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

