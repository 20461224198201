import React from 'react';
import SCard from '../Card/SCard';

export default function ESCc() {
  const cardsData = [
    { title: "PAT Testing Up To 10 Items", price: 57.99, link: "/bookS" },
    { title: "Domestic Electrical Safety Certificate EICR 1 – 3 Bedrooms – 1 Consumer Unit Up to 12 Circuits", price: 98.99, link: "/bookS" },
    { title: "Domestic Electrical Safety Certificate EICR 4 Bedrooms – 1 Consumer Unit Up to 12 Circuits", price: 119.99, link: "/bookS" },
    { title: "Commercial Electrical Certificate (EICR) – 1 Consumer Unit Up to 12 Circuits", price: 148.99 },
    { title: "Domestic Electrical Safety Certificate EICR 5 Bedrooms – 1 Consumer Unit Up to 12 Circuits", price: 149.99, link: "/bookS" },
    { title: "Domestic Electrical Safety Certificate EICR 6 Bedrooms – 1 Consumer Unit Up to 12 Circuits", price: 157.99, link: "/bookS" },
    { title: "Fuse Box Installation", price: 414.99, link: "/bookS" }
  ];
  return (
    <div className="container">
      <div className="service-container">
        <h1 className="text-center service-h text-shadow-custom">Electric Safety</h1>
      </div>
      <div className="card-wrapper" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {cardsData.map((card, index) => (
          <SCard key={index} title={card.title} price={card.price} link={card.link} />
        ))}
      </div>
    </div>
  )
}
