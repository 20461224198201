import React from 'react';
import SCard from '../Card/SCard';

export default function EPCc() {
  const cardsData = [
    { title: "Domestic EPC Certificate Up To 3 Bedrooms", price: 69.99, link: "/bookS" },
    { title: "Commercial EPC Certificate", price: 287.99, link: "/bookS" }
  ];
  return (
    <div className="container">
      <div className="service-container">
        <h1 className="text-center service-h text-shadow-custom">Energy Performance</h1>
      </div>
      <div className="card-wrapper" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {cardsData.map((card, index) => (
          <SCard key={index} title={card.title} price={card.price} link={card.link} />
        ))}
      </div>
    </div>
  )
}
