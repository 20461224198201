import React from 'react';
// import com1 from '../../Images/Company/company name1.svg';
// import com2 from '../../Images/Company/company name2.svg';
// import com3 from '../../Images/Company/company name3.svg';
// import com4 from '../../Images/Company/company name4.svg';
// import com5 from '../../Images/Company/company name5.svg';
// import com6 from '../../Images/Company/company name6.svg';

export default function Engineers() {
    return (
        <div>
            <div className="container mb-5">
                <h1 className="text-center text-shadow-custom" style={{ marginBottom: '0', fontWeight: 'bold', marginTop: '70px' }}>Certified and Trusted</h1><br />
                <h1 className="text-center text-shadow-custom" style={{ marginBottom: '0', fontWeight: 'bold', marginTop: '-20px' }}>Professional Engineers</h1>
                {/* <div className="image-container">
                    <img src={com1} alt="Company 1" className="company-logo" />
                    <img src={com2} alt="Company 2" className="company-logo" />
                    <img src={com3} alt="Company 3" className="company-logo" />
                    <img src={com4} alt="Company 4" className="company-logo" />
                    <img src={com5} alt="Company 5" className="company-logo" />
                    <img src={com6} alt="Company 6" className="company-logo" />
                </div> */}
            </div>
        </div>
    );
}
