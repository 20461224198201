import React from 'react';
import hero2 from '../../Images/Homepage/Hero2.png';

export default function HeroSection() {
    return (
        <div>
            <div className="hero-container">
                <div className="heroBG">
                    <h1 className="text-center mb-3" style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}> Property All Safety Checks Limited</h1>
                    <div className="content">
                        <div className="left-content">
                            <h1 style={{ textAlign: "start" }} className="text-shadow-custom">
                                <b>PASCL is your <br /> ALL-in-one <br /> Services Provider</b>
                            </h1>
                            <div className="d-flex flex-column" style={{ textAlign: "left", color: "#707070" }}>
                                <p><span style={{ color: "#FFD700", fontSize: "22px" }}>&#10070;</span> UK Based Services Provider Platform</p>
                                <p><span style={{ color: "#FFD700", fontSize: "22px" }}>&#10070;</span> 99% Customer Satisfaction</p>
                            </div>
                            <a href="/bookS" className="btn btn-dark custom-button">
                                Book Services &nbsp;<strong>&#10148;</strong>
                            </a>
                        </div>
                        <div className="right-content">
                            <img src={hero2} alt='img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
