import React from 'react';
import '../Services/Card/CARD.css';

const HomePageCard = ({ imageSrc, title, price, Link }) => {
  const formatPrice = (price) => {
    return price % 1 === 0 ? price.toFixed(0) : price.toFixed(2);
  };

  return (
    <div className="card-container m-3">
      <div className="card shadow-lg" style={{ width: "20rem" }}>
        <img src={imageSrc} className="card-img-top" alt={title} />
        <div className="card-body">
          <h5 className="card-title text-shadow-custom">{title}</h5>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "bold", fontSize: "24px", marginLeft: "32px" }}>
              &#163; {formatPrice(price)}
            </span>
            <a href={Link} className="btn btn-dark custom-button" style={{ borderRadius: "50px" }}>
              View Details &nbsp;<strong>&#10148;</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageCard;
