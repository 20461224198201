import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import HomePage from './Pages/homepage.jsx';
import Footer from './Components/footer.jsx';
import BookServices from './Pages/BookServices.jsx';
import AboutUs from './Pages/AboutUs.jsx';
import TermConditions from './Pages/Term&Conditions.jsx';
import PrivacyPolicy from './Pages/PrivacyPolicy.jsx';
import Services from './Pages/services.jsx';
import EPC from './Pages/EPC.jsx';
import ESC from './Pages/ESC.jsx';
import FRA from './Pages/FRA.jsx';
import GSC from './Pages/GSC.jsx';
import InventoryService from './Pages/InventoryService.jsx';
import LayoutNav from './LayoutNav.js';
import FRAhmo from './Components/Services/FRA/FRAhmo.jsx';
import FRAca from './Components/Services/FRA/FRAca.jsx';

function App() {
  return (
    <BrowserRouter>
      <LayoutNav>
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path='/' element={<HomePage />} />
          <Route path='/bookS' element={<BookServices />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/terms' element={<TermConditions />} />
          <Route path='/policy' element={<PrivacyPolicy />} />
          <Route path='/Services' element={<Services />} />
          <Route path='/EPC' element={<EPC />} />
          <Route path='/ESC' element={<ESC />} />
          <Route path='/FRA' element={<FRA />} />
          <Route path='/GSC' element={<GSC />} />
          <Route path='/InventoryService' element={<InventoryService />} />
          <Route path='/frahmos' element={<FRAhmo />} />
          <Route path='/fraca' element={<FRAca />} />
        </Routes>
        <Footer className="footer" />
      </LayoutNav>
    </BrowserRouter>
  );
}

export default App;
