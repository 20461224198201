import React from 'react';
import '../Styles/epc.css';
import EPCc from '../Components/Services/EPC/EPCc';
import Engineers from '../Components/HomePage/Engineers';
import ContactInfo from '../Components/Services/ContactInfo';

export default function EPC() {
  return (
    <div>
      <div>
        <div className="epc-section">
          <div className="container">
            <h1 className="text-center epc-h text-shadow-custom">Energy Performance</h1>
            <p className="text-center epc-p"> Secure your Energy Performance Certificate (EPC) and Electrical Installation Condition Report (EICR) effortlessly. Unlock special discounts and experience top-tier, hassle-free service. Keep your property safe and compliant with a thorough Energy Performance Report that brings you lasting peace of mind. </p>
          </div>
        </div>
        <div className="container">
          <EPCc />
        </div>
        <div className="container">
          <h1 className="text-center epc-h">
            <span className="heading-main text-shadow-custom">Energy Performance Certificate (EPC)</span>
            <br />
            <span className="heading-sub"> Our skilled engineers are committed to helping your property achieve optimal energy efficiency while fully complying with legal standards. Rely on us for accurate and dependable EPC assessments, ensuring your property stays safe and compliant without hassle. </span>
          </h1>
          <ContactInfo />
          <Engineers />
        </div>
      </div>
    </div>
  )
}
