import React from 'react';
import SCard from '../Card/SCard';

export default function GSCc() {
  const cardsData = [
    { title: "Meter Check only", price: 28.99 },
    { title: "Gas Safety Certificate – Domestic – Meter & 1 appliance", price: 47.98, link: "/bookS" },
    { title: "Gas Safety Certificate – Domestic – Meter & 2 appliance", price: 57.98, link: "/bookS" },
    { title: "Gas Safety Certificate – Domestic – Meter & 3 appliances", price: 67.98, link: "/bookS" },
    { title: "Gas Safety Certificate – Domestic – Meter & 4+ appliances", price: 77.98, link: "/bookS" },
    { title: "Carbon Monoxide Alarm", price: 79.99, link: "/bookS" },
    { title: "Gas Safety Certificate – Domestic – “Discounted Offer” Boiler Service + Gas Certificate & 2 appliances", price: 89.98, link: "/bookS" },
    { title: "Gas Safety Certificate – Commercial – 1 appliance", price: 198.99, link: "/bookS" },
    { title: "Gas Safety Certificate – Commercial – 2 appliances", price: 244.99, link: "/bookS" },
    { title: "Gas Safety Certificate – Commercial – Boiler Service", price: 279.99, link: "/bookS" }
  ];
  return (
    <div className="container">
      <div className="service-container">
        <h1 className="text-center service-h text-shadow-custom">Gas Safety Certificate</h1>
      </div>
      <div className="card-wrapper" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {cardsData.map((card, index) => (
          <SCard key={index} title={card.title} price={card.price} link={card.link} />
        ))}
      </div>
    </div>
  )
}
