import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className="container mb-3">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h1 className="text-center mb-4 text-shadow-custom">Privacy Policy</h1>
          <h5 className="text-shadow-custom">Privacy Policy for Property All Safety Checks Limited</h5>
          <p>
            At Property All Safety Checks Limited, accessible at <a href="https://pascl.co.uk/" target="_blank" rel="noopener noreferrer">https://pascl.co.uk/</a>, we prioritize the privacy of our visitors. This Privacy Policy outlines the types of information we collect, how we use it, and your rights regarding your personal data.
          </p>
          <p>
            If you have any questions or need further information about our Privacy Policy, please feel free to <a href="mailto:support@pascl.co.uk">contact us</a>.
          </p>
          <p>
            This policy applies exclusively to our online activities and is relevant to visitors who share or collect information on our website. It does not extend to information collected offline or through other channels.
          </p>
          <h5 className="text-shadow-custom">Consent</h5>
          <p>
            By accessing our website, you consent to our Privacy Policy and agree to its terms.
          </p>
          <h5 className="text-shadow-custom">Information We Collect</h5>
          <p>
            We will clearly inform you about the personal information we request and the purpose for which it is needed at the time of collection.
          </p>
          <p>
            If you reach out to us directly, we may collect additional information such as your name, email address, phone number, the contents of your message, and any attachments you may provide.
          </p>
          <p>
            When you register for an account, we may ask for your contact details, including your name, company name, address, email address, and phone number.
          </p>
          <h5 className="text-shadow-custom">How We Use Your Information</h5>
          <p>We utilize the information we collect in several ways, including:</p>
          <ul>
            <li>Operating and maintaining our website</li>
            <li>Enhancing, personalizing, and expanding our website</li>
            <li>Analyzing how you use our website</li>
            <li>Developing new products, services, and features</li>
            <li>Communicating with you directly or through partners for customer service, updates, and marketing</li>
            <li>Sending you emails</li>
            <li>Preventing and detecting fraud</li>
          </ul>
          <h5 className="text-shadow-custom">Log Files</h5>
          <p>
            Property All Safety Checks Limited adheres to standard log file practices. Log files track visitors to our website as part of analytics services provided by hosting companies. Information collected may include IP addresses, browser type, Internet Service Provider (ISP), date and time stamps, referring/exit pages, and click counts. This data is not linked to any personally identifiable information and is used for trend analysis, site administration, and demographic gathering.
          </p>
          <h5 className="text-shadow-custom">Cookies and Web Beacons</h5>
          <p>
            Like many websites, Property All Safety Checks Limited employs cookies to store visitor preferences and track the pages accessed. This information helps us enhance user experience by customizing our content according to browser type and other data.
          </p>
          <h5 className="text-shadow-custom">Google DoubleClick DART Cookie</h5>
          <p>
            Google, as a third-party vendor on our site, uses cookies known as DART cookies to serve ads based on your previous visits to our website and other sites on the internet. Visitors can opt-out of DART cookies by visiting the Google ad and content network Privacy Policy at <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">https://policies.google.com/technologies/ads</a>.
          </p>
          <h5 className="text-shadow-custom">Advertising Partners</h5>
          <p>
            Some of our advertising partners may also use cookies and web beacons. Each partner has its own Privacy Policy regarding user data. Below is a list of our advertising partners along with links to their respective policies:
          </p>
          <ul>
            <li>
              <p>Google</p>
              <p><a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">https://policies.google.com/technologies/ads</a></p>
            </li>
          </ul>
          <h5 className="text-shadow-custom">Third Party Privacy Policies</h5>
          <p>
            The Privacy Policy of Property All Safety Checks Limited does not apply to other advertisers or websites. We recommend consulting the Privacy Policies of third-party ad servers for more information regarding their practices and how to opt-out of certain options.
          </p>
          <p>
            You have the option to disable cookies through your browser settings. For more detailed information on managing cookies with specific web browsers, please visit the respective websites.
          </p>
          <h5 className="text-shadow-custom">CCPA Privacy Rights (Do Not Sell My Personal Information)</h5>
          <p>
            Under the CCPA, California consumers have rights including the ability to:
          </p>
          <ul>
            <li>Request disclosure of the categories and specific pieces of personal data collected about them.</li>
            <li>Request deletion of their personal data.</li>
            <li>Request that their data not be sold.</li>
          </ul>
          <p>
            We have one month to respond to such requests. If you wish to exercise any of these rights, please contact us.
          </p>
          <h5 className="text-shadow-custom">GDPR Data Protection Rights</h5>
          <p>
            We want to ensure you are fully informed of your data protection rights. Every user is entitled to:
          </p>
          <ul>
            <li>The right to access – request copies of your personal data.</li>
            <li>The right to rectification – request correction of inaccurate information or completion of incomplete data.</li>
            <li>The right to erasure – request deletion of your personal data under certain conditions.</li>
            <li>The right to restrict processing – request restriction of processing your personal data under specific circumstances.</li>
            <li>The right to object to processing – object to our processing of your personal data under certain conditions.</li>
            <li>The right to data portability – request transfer of your data to another organization or directly to you under certain conditions.</li>
          </ul>
          <p>
            We will respond to requests within one month. If you would like to exercise any of these rights, please reach out to us.
          </p>
          <h5 className="text-shadow-custom">Children’s Information</h5>
          <p>
            We are committed to protecting children while they use the internet. We encourage parents and guardians to monitor and guide their online activities.
          </p>
          <p>
            Property All Safety Checks Limited does not knowingly collect personally identifiable information from children under the age of 13. If you believe your child has provided such information, please contact us immediately, and we will take appropriate measures to remove that information from our records.
          </p>
        </div>
      </div>
    </div>
  );
}