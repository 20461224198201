import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Styles/BookServices.css';
import ContactInfo from '../Components/Services/ContactInfo';

export default function ContactUs() {
    const [commercialChecked, setCommercialChecked] = useState(false);
    const [residentialChecked, setResidentialChecked] = useState(false);
    const [date, setDate] = useState("");
    const [time, setTime] = useState("08:00");
    const [additionalInfo, setAdditionalInfo] = useState("");

    useEffect(() => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        setDate(`${year}-${month}-${day}`);
    }, []);

    const handleDateChange = (e) => {
        setDate(e.target.value);
    };

    const handleTimeChange = (e) => {
        setTime(e.target.value);
    };

    const handleInfoChange = (e) => {
        setAdditionalInfo(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            commercialChecked,
            residentialChecked,
            date,
            time,
            additionalInfo
        };
        try {
            const response = await axios.post("your_endpoint_here", formData);
            console.log(response.data);
            setCommercialChecked(false);
            setResidentialChecked(false);
            setDate("");
            setTime("08:00");
            setAdditionalInfo("");
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <div className="booking-pcontainer">
            <form onSubmit={handleSubmit}>
                <div className="elementor-icon-box-content">
                    <h3 className="elementor-icon-box-title text-center text-shadow-custom">
                        <a href="tel:+442081455358">
                            &#9990; Talk to a Friendly Advisor
                        </a>
                    </h3>
                    <p className="elementor-icon-box-description">
                        We provide expert EPC, EICR, Gas Safety Certificates, and Fire Risk Assessments for both commercial and residential properties across the UK. Our services ensure that you meet all legal requirements and maintain compliance.
                    </p>
                </div>
                <h2 className="elementor-heading-title elementor-size-default text-shadow-custom">Book an Appointment</h2>
                <p className="description-text mb-2">Our team will contact you to arrange the service shortly. Please fill in the details.</p>

                <div className="mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="First and Last Name"
                        required
                    />
                </div>
                <div className="mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Email Address"
                        required
                    />
                </div>
                <div className="mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="mb-1">
                        <strong>Services are available within the United Kingdom.</strong>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Street Address"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="mb-1">
                        <strong>Postcode</strong>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Postcode"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="mb-1">
                        <strong>Select a Property Type</strong>
                    </label>
                    <div className="checkbox-group">
                        <div className="checkbox-group-item">
                            <input
                                type="checkbox"
                                name="commercial"
                                className="mx-1"
                                checked={commercialChecked}
                                onChange={() => setCommercialChecked(!commercialChecked)}
                            />
                            <label>Commercial</label>
                        </div>
                        <div className="checkbox-group-item">
                            <input
                                type="checkbox"
                                name="residential"
                                className="mx-1"
                                checked={residentialChecked}
                                onChange={() => setResidentialChecked(!residentialChecked)}
                            />
                            <label>Residential</label>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <label className="mb-1">
                        <strong>Select Service</strong>
                    </label>
                    <div className="checkbox-group">
                        <div className="checkbox-group-item">
                            <input
                                type="checkbox"
                                name="Fire Risk Assessment"
                                className="mx-1"
                            />
                            <label>Fire Risk Assessment</label>
                        </div>
                        <div className="checkbox-group-item">
                            <input
                                type="checkbox"
                                name="Gas Safety Certificate"
                                className="mx-1"
                            />
                            <label>Gas Safety Certificate</label>
                        </div>
                        <div className="checkbox-group-item">
                            <input
                                type="checkbox"
                                name="EICR"
                                className="mx-1"
                            />
                            <label>Electrical Installation Condition Reports (EICR)</label>
                        </div>
                        <div className="checkbox-group-item">
                            <input
                                type="checkbox"
                                name="EPC"
                                className="mx-1"
                            />
                            <label>Energy Performance Certificate (EPC)</label>
                        </div>
                        <div className="checkbox-group-item">
                            <input
                                type="checkbox"
                                name="Fire alarm installation"
                                className="mx-1"
                            />
                            <label>Fire alarm installation</label>
                        </div>
                        <div className="checkbox-group-item">
                            <input
                                type="checkbox"
                                name="Carbon Monoxide Alarms"
                                className="mx-1"
                            />
                            <label>Carbon Monoxide Alarms</label>
                        </div>
                        <div className="checkbox-group-item">
                            <input
                                type="checkbox"
                                name="Emergency Lighting"
                                className="mx-1"
                            />
                            <label>Emergency Lighting</label>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <label className="mb-1">
                        <strong>Choose a Date</strong>
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        placeholder="Choose a Date"
                        required
                        min={new Date().toISOString().split("T")[0]}
                        max={new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split("T")[0]}
                        onChange={handleDateChange}
                        value={date}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="mb-3">
                    <label className="mb-1">
                        <strong>Choose a Time Slot (8 am to 6 pm)</strong>
                    </label>
                    <select
                        className="form-select"
                        required
                        value={time}
                        onChange={handleTimeChange}
                        style={{ cursor: "pointer" }}
                    >
                        <option value="08:00">Morning Slot: 8 am - 1 pm</option>
                        <option value="13:00">Evening Slot: 1 pm - 6 pm</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className="mb-1">
                        <strong>Further Information</strong>
                    </label>
                    <textarea
                        className="form-control"
                        style={{ height: '200px', resize: 'none' }}
                        placeholder="Is there any other information you want to provide?"
                        value={additionalInfo}
                        onChange={handleInfoChange}
                    />
                </div>
                <div className="mb-3">
                    <button type="submit" className="btn booking-pbtn-primary green-button">
                        Submit
                    </button>
                </div>
            </form>
            <ContactInfo />
        </div>
    );
}
