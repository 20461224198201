import React from 'react';

export default function TermConditions() {
    return (
        <div className="container mb-3">
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <h1 className="text-center mb-4 text-shadow-custom">Terms & Conditions</h1>

                    {/* Disclaimer Section */}
                    <section>
                        <h2 className="text-shadow-custom">Disclaimer</h2>
                        <p>The websites and companies referenced on the Property All Safety Check Limited site are not governed by us, and we cannot be held accountable for their content or any losses you may incur from using them. While we provide these links for your convenience and potential benefit, we are unable to supervise their performance or content. You agree to waive any claims against Property All Safety Check Limited arising from your use of these external sites.</p>
                    </section>

                    {/* Ownership Section */}
                    <section>
                        <h2 className='text-shadow-custom'>Ownership of Our Website</h2>
                        <p>Property All Safety Check Limited owns and manages this website. All materials displayed or accessible on this site, such as text, graphics, photographs, images, audio, illustrations, and software (“content”), are owned by Property All Safety Check Limited and its content providers.</p>
                    </section>

                    {/* Protection of Intellectual Property Section */}
                    <section>
                        <h2 className='text-shadow-custom'>Protection of Intellectual Property</h2>
                        <p>All elements of the Property All Safety Check Limited website, including its design and content, are safeguarded by copyright, trademark, and other intellectual property laws. Unless explicitly allowed through a separate agreement, no part of this website or its content may be reproduced or distributed. The rights to this website and its content remain with Property All Safety Check Limited or its licensors, unless otherwise agreed.</p>
                    </section>

                    {/* Indemnification for Breach Section */}
                    <section>
                        <h2 className='text-shadow-custom'>Indemnification for Breach</h2>
                        <p>You agree to protect Property All Safety Check Limited, along with its subsidiaries and affiliates, from any claims, damages, losses, or expenses resulting from your violation of these terms or your unauthorized use of our content.</p>
                    </section>

                    {/* How You May Use the Content Section */}
                    <section>
                        <h2 className='text-shadow-custom'>Permitted Use of Content</h2>
                        <p>Images, excluding comp images, can only be used for commercial purposes after securing a license agreement and paying the applicable fee. Comp images are permitted solely for internal layouts, samples, or client proofing.</p>
                        {/* Add more details as necessary */}
                    </section>

                    {/* Acceptable Use Policy Section */}
                    <section>
                        <h2 className='text-shadow-custom'>Acceptable Use Policy</h2>
                        <ul>
                            <li>In a manner that violates any local, national, or international laws or regulations.</li>
                            <li>For any illegal or fraudulent purposes.</li>
                            <li>To send unsolicited or unauthorized advertisements or promotional materials (spam).</li>
                            <li>To knowingly transmit harmful programs or code designed to disrupt or damage computer systems.</li>
                        </ul>
                        <p>You also agree not to reproduce, duplicate, or resell any section of our site against these terms and not to access or disrupt any part of our website without authorization.</p>
                    </section>

                    {/* Copyright Infringement Policy Section */}
                    <section>
                        <h2 className='text-shadow-custom'>Copyright Infringement Policy</h2>
                        <p>In line with the Digital Millennium Copyright Act (“DMCA”) and relevant laws, Property All Safety Check Limited has a policy of terminating the accounts of users who infringe on the intellectual property rights of others. If you believe any content on our website violates your copyright, you may notify us at <a href='mailto:info@pascl.co.uk'>info@pascl.co.uk</a>.</p>
                        {/* Add more details as necessary */}
                    </section>

                    {/* Model and Property Releases Section */}
                    <section>
                        <h2 className='text-shadow-custom'>Model and Property Releases</h2>
                        <p>Property All Safety Check Limited does not guarantee the existence of any releases associated with images nor does it provide assurances regarding the use of names, trademarks, or artworks shown in these images. It is your responsibility to obtain all necessary permissions for the reproduction of these images.</p>
                    </section>

                    {/* Contact Information Section */}
                    <section>
                        <h2 className='text-shadow-custom'>Contact Information</h2>
                        <p>If you wish to update your information or believe that our records are incorrect, please reach out to us at <a href='mailto:info@pascl.co.uk'>info@pascl.co.uk</a>.</p>
                        {/* Add more contact options as necessary */}
                    </section>

                    {/* Data Protection Disclaimer Section */}
                    <section>
                        <h2 className='text-shadow-custom'>Data Protection Disclaimer</h2>
                        <p>We may collect personal information from you. For details on how we handle your data, please refer to our Privacy Policy.</p>
                        {/* Add link to Privacy Policy */}
                    </section>
                </div>
            </div>
        </div>
    );
}
