import React from 'react';
import FRAc from '../Components/Services/FRA/FRAc';
import GSCc from '../Components/Services/GSC/GSCc';
import ESCc from '../Components/Services/ESC/ESCc';
import EPCc from '../Components/Services/EPC/EPCc';
import Inventoryc from '../Components/Services/Inventory/Inventoryc';
import ASc from '../Components/Services/ASc';
import OurServices from '../Components/HomePage/OurServices';
import ContactInfo from '../Components/Services/ContactInfo';

export default function services() {
  return (
    <div>
      <div>
        <div className="p-5 m-5">
          <h2 className="text-center service-h text-shadow-custom">
            Book Online for Fire Risk Assessments, Electrical, and Gas Safety Certificates.
            Enjoy Discounted Rates, Hassle-Free Services, and Trusted Expertise.
            Serving London and Beyond.
          </h2>
        </div>
      </div>
      <div className="container">
        <OurServices />
        <FRAc />
        <GSCc />
        <ESCc />
        <EPCc />
        <Inventoryc />
        <ASc />
        <ContactInfo />
      </div>
    </div>
  )
}
