import React from 'react';
import SCard from '../Card/SCard';

export default function Inventoryc() {
  const cardsData = [
    { title: "Check-in Report", price: 99.99, link: "/bookS" },
    { title: "Check-out Report", price: 124.99, link: "/bookS" },
    { title: "Inventory Report", price: 207.99, link: "/bookS" },
  ];
  return (
    <div className="container">
      <div className="service-container">
        <h1 className="text-center service-h text-shadow-custom">Inventory Services</h1>
      </div>
      <div className="card-wrapper" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {cardsData.map((card, index) => (
          <SCard key={index} title={card.title} price={card.price} link={card.link} />
        ))}
      </div>
    </div>
  )
}
