import React from 'react';
import '../Styles/footer.css';
import twiter from '../Images/Footer/twiter.svg';
import facebook from '../Images/Footer/Facebook.svg';
import insta from '../Images/Footer/insta.svg';

export default function Footer() {
    const buttonColor = 'rgba(255, 255, 255, 0.4)';
    return (
        <div className="footerBG">
            <div className="footer-container">
                <h1 className="text-center text-shadow-custom" style={{ marginBottom: '0', fontWeight: 'bold', marginTop: '70px', color: 'white' }}>Work Easy</h1>
                <h1 className="text-center text-shadow-custom" style={{ marginBottom: '60px', fontWeight: 'bold', color: 'white' }}>Grow Further With PASCL</h1>
                <div className="d-flex justify-content-center mb-5">
                    <a href='/bookS' className=" btn btn-light text-shadow-custom" style={{ borderRadius: "50px", padding: "10px 20px" }}>
                        Book Services &nbsp;<strong>&#10148;</strong>
                    </a>
                </div>
                <div className="d-flex justify-content-between mb-3">
                    <div>
                        <h1 className="m text-shadow-custom" style={{ marginBottom: '0', fontWeight: 'bold', color: 'white' }}>PASCL</h1>
                    </div>
                    <div>
                        <a href="/" style={{ padding: "0px 10px" }}>
                            <img src={twiter} alt="Twitter Logo" style={{ width: "50px", height: "50px", border: "2px solid #323232", }} />
                        </a>
                        <a href='https://www.facebook.com/profile.php?id=61559619645845' style={{ padding: "0px 10px" }}>
                            <img src={facebook} alt='img' style={{ width: "50px", height: "50px", border: "2px solid #323232", }} />
                        </a>
                        <a href='/' style={{ padding: "0px 10px" }}>
                            <img src={insta} alt='img' style={{ width: "50px", height: "50px", border: "2px solid #323232", }} />
                        </a>
                    </div>
                </div>
                <div className="footer-links" style={{ borderTop: `2px solid ${buttonColor}`, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>
                        <p className="m text-shadow-custom" style={{ marginBottom: '0', fontWeight: 'bold', color: 'white' }}>&#169; Copyright 2024. All Rights Reserved.</p>
                    </div>
                    <div className="text-shadow-custom" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <a href='/' className='btn' style={{ color: buttonColor, margin: '5px' }}>Home</a>
                        <a href='/Services' className='btn' style={{ color: buttonColor, margin: '5px' }}>Services</a>
                        <a href="mailto:info@pascl.co.uk" className='btn' style={{ color: buttonColor, margin: '5px' }}>Contact Us</a>
                        <a href='/about' className='btn' style={{ color: buttonColor, margin: '5px' }}>About Us</a>
                        <a href='/policy' className='btn' style={{ color: buttonColor, margin: '5px' }}>Privacy Policy</a>
                        <a href='/terms' className='btn' style={{ color: buttonColor, margin: '5px' }}>Terms & Conditions</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
