import React from 'react';
import '../Styles/esc.css';
import ESCc from '../Components/Services/ESC/ESCc';
import Engineers from '../Components/HomePage/Engineers';
import ContactInfo from '../Components/Services/ContactInfo';

export default function ESC() {
  return (
    <div>
      <div>
        <div className="esc-section">
          <div className="container">
            <h1 className="text-center esc-h text-shadow-custom">Electrical Safety Certificate (EICR)</h1>
            <p className="text-center esc-p"> Serving all of London, we make it simple to obtain your EICR and EPC certifications. Enjoy seamless, hassle-free service while prioritizing the safety of your property. Our comprehensive Electrical Safety Inspections ensure your installations meet all regulatory standards, giving you peace of mind and keeping you fully compliant. </p>
          </div>
        </div>
        <div className="container">
          <ESCc />
        </div>
        <div className="container">
          <h1 className="text-center esc-h">
            <span className="heading-main text-shadow-custom">Electrical Installation Condition Report (EICR)</span>
            <br />
            <span className="heading-sub"> Our skilled engineers are fully trained and certified to carry out detailed inspections of your property's electrical systems, verifying compliance with safety regulations. Count on us for dependable EICR assessments that help you maintain a safe and legally compliant environment effortlessly. </span>
          </h1>
          <ContactInfo />
          <Engineers />
        </div>
      </div>
    </div>
  )
}
