import React from 'react';
import '../Styles/gsc.css';
import GSCc from '../Components/Services/GSC/GSCc';
import Engineers from '../Components/HomePage/Engineers';
import ContactInfo from '../Components/Services/ContactInfo';

export default function GSC() {
  return (
    <div>
      <div>
        <div className="gsc-section">
          <div className="container">
            <h1 className="text-center gsc-h text-shadow-custom">Gas Safety Certificate (CP12) from £28.99</h1>
            <p className="text-center gsc-p">
              With over 20 years of experience, we've been a trusted choice for safety across London and the M25 area. Become part of our satisfied customer community today—enjoy special discounts and effortless, dependable service. Ensure safety and compliance with a thorough gas safety inspection, giving you confidence and peace of mind.
            </p>
          </div>
        </div>
        <div className="container">
          <GSCc />
        </div>
        <div className="container">
          <h1 className="text-center gsc-h">
            <span className="heading-main text-shadow-custom">Not sure which safety check you need?</span>
            <br />
            <span className="heading-sub">
              Choosing the right safety certificate can be confusing. Let our friendly team guide you in selecting the best option.
            </span>
          </h1>
          <ContactInfo />
          <Engineers />
        </div>
      </div>
    </div>
  )
}
