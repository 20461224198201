import React from 'react';
import '../Styles/isc.css';
import Inventoryc from '../Components/Services/Inventory/Inventoryc';
import Engineers from '../Components/HomePage/Engineers';
import ContactInfo from '../Components/Services/ContactInfo';

export default function InventoryService() {
  return (
    <div>
      <div>
        <div className="isc-section">
          <div className="container">
            <h1 className="text-center isc-h text-shadow-custom">Our Inventory Services Keep You Worry-Free</h1>
            <p className="text-center isc-p">
              We offer a complete range of services designed to give you peace of mind. At the end of each tenancy, we conduct a thorough inventory check with the tenants, read the electric meter, and hand over the property keys. Even after the keys are handed over, we conduct a final inspection to identify any missing or damaged items. Our dedicated inventory team ensures everything is checked meticulously and handled professionally.
            </p>
          </div>
        </div>
        <div className="container">
          <Inventoryc />
        </div>
        <div className="container">
          <h1 className="text-center isc-h">
            <span className="heading-main text-shadow-custom">Exceptional Inventory Services You Can Trust</span>
            <br />
            <span className="heading-sub">
              Leave the hassle to us. Our skilled team will prepare a detailed inventory list of the property's contents, fixtures, and fittings, making your workload lighter. We ensure that all items are handled with care, delivered in pristine condition, and checked off before being handed to the tenants.
            </span>
          </h1>
          <ContactInfo />
          <Engineers />
        </div>
      </div>
    </div>
  )
}