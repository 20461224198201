import React, { useState } from 'react';
import SScard from '../Card/SScard';
import ContactInfo from '../ContactInfo';

export default function FRAca() {
    const [prices, setPrices] = useState(108.99);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue.includes('1 storey')) {
            setPrices(108.99);
        } else if (selectedValue.includes('2 storey')) {
            setPrices(138.99);
        } else if (selectedValue.includes('3 storey')) {
            setPrices(158.99);
        }
    };

    return (
        <div className="" style={{ marginTop: '125px' }}>
            <div>
                <div className="text-center">
                    <a href='/'>Home</a> / <a href='/FRA'>Fire Risk Assessment</a> / For Communal Area
                </div>
                <form className="container-fluid g-0 mb-5">
                    <div className="d-flex justify-content-center align-items-center align-content-center">
                        <div className="m-3">
                            <SScard title={"Fire Risk Assessments For Communal Area"} price={prices} />
                        </div>
                        <div className="">
                            <p style={{ fontWeight: "700" }}>Fire Risk Assessments For Communal Area</p>
                            <label className="mb-1"> <strong>Choose an option</strong></label>
                            <select
                                className="form-select"
                                required
                                style={{ cursor: "pointer" }}
                                onChange={handleSelectChange}
                            >
                                <option value="1 storey">1 storey communal area &#163;108.99+VAT</option>
                                <option value="2 storey">2 Storey communal area &#163;138.99+VAT</option>
                                <option value="3 storey">3 Storey communal area &#163;158.99+VAT</option>
                            </select>
                            <div>
                                <span style={{ fontWeight: "bold", fontSize: "42px", marginBottom: "10px" }}>
                                    &#163; {prices}
                                </span>
                            </div>
                            <div>
                                <a href="/bookS" className="btn btn-dark custom-button" style={{ borderRadius: "50px", textAlign: "center" }}>
                                    Book Now &nbsp;<strong>&#10148;</strong>
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="mb-4">
                <ContactInfo />
            </div>
        </div >
    )
}
