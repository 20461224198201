import React from 'react';
import SCard from '../Card/SCard';

export default function FRAc() {
  const cardsData = [
    { title: "1 Bed Studio Flat", price: 78.99, link: "/bookS" },
    { title: "Fire Risk Assessments For Communal Area", price: 108.99, link: "/fraca" },
    { title: "Mains Smoke Alarms Installation", price: 133.99, link: "/bookS" },
    { title: "Fire Risk Assessments For HMO’s & Rental Homes", price: 138.99, link: "/frahmos" },
    { title: "Emergency Lights Installation", price: 139.99, link: "/bookS" },
    { title: "Fire Risk Assessments For All Business Sectors", price: 158.99, link: "/bookS" },
  ];

  return (
    <div className="container">
      <div className="service-container">
        <h1 className="text-center service-h text-shadow-custom">Fire Risk Assessment</h1>
      </div>
      <div className="card-wrapper" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {cardsData.map((card, index) => (
          <SCard key={index} title={card.title} price={card.price} link={card.link} />
        ))}
      </div>
    </div>
  );
}
