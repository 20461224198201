import React, { useState } from 'react';
import SScard from '../Card/SScard';
import ContactInfo from '../ContactInfo';

export default function FRAhmo() {
    const [prices, setPrices] = useState(138.99);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue.includes('1-3 Bedroom')) {
            setPrices(138.99);
        } else if (selectedValue.includes('4-6 Bedroom')) {
            setPrices(159.69);
        }
    };

    return (
        <div className="" style={{ marginTop: '125px' }}>
            <div>
                <div className="text-center">
                    <a href='/'>Home</a> / <a href='/FRA'>Fire Risk Assessment</a> / For HMO’s & Rental Homes
                </div>
                <form className="container-fluid g-0 mb-5">
                    <div className="d-flex justify-content-center align-items-center align-content-center">
                        <div className="m-3">
                            <SScard title={"Fire Risk Assessments For HMO’s & Rental Homes"} price={prices} />
                        </div>
                        <div className="">
                            <p style={{ fontWeight: "700" }}>Fire Risk Assessments For HMO's & Rental Homes</p>
                            <label className="mb-1"> <strong>Choose an option</strong></label>
                            <select
                                className="form-select"
                                required
                                style={{ cursor: "pointer" }}
                                onChange={handleSelectChange}
                            >
                                <option value="£139+VAT for 1-3 Bedroom">&#163;138.99+VAT for 1-3 Bedroom</option>
                                <option value="£159.99+VAT for 4-6 Bedroom">&#163;159.69+VAT for 4-6 Bedroom</option>
                            </select>
                            <div>
                                <span style={{ fontWeight: "bold", fontSize: "42px", marginBottom: "10px" }}>
                                    &#163; {prices}
                                </span>
                            </div>
                            <div>
                                <a href="/bookS" className="btn btn-dark custom-button" style={{ borderRadius: "50px", textAlign: "center" }}>
                                    Book Now &nbsp;<strong>&#10148;</strong>
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="mb-4">
                <ContactInfo />
            </div>
        </div >
    );
}
