import React from 'react';
import '../Styles/fra.css';
import Engineers from '../Components/HomePage/Engineers';
import ContactInfo from '../Components/Services/ContactInfo';
import FRAc from '../Components/Services/FRA/FRAc';

export default function FRA() {
  return (
    <div>
      <div className="fra-section">
        <div className="container">
          <h1 className="text-center fra-h text-shadow-custom">Fire Risk Assessment & Asbestos Survey</h1>
          <p className="text-center fra-p"> We ensure complete compliance and safety with every inspection. Whether it's a home, business, or venue, our thorough Fire Risk Assessments (FRA) cover all necessary aspects for both residential and commercial properties. Schedule your appointment at a time that suits you and enjoy a seamless, professional service. Trust PASCL to deliver a precise assessment, securing your premises and providing peace of mind. Safeguard the well-being of your employees, customers, and residents with our expert FRA solutions. </p>
        </div>
      </div>
      <div className="container">
        <FRAc />
      </div>
      <div className="container">
        <h1 className="text-center fra-h">
          <span className="heading-main text-shadow-custom">PASCL identifies potential fire hazards & assesses those at risk,</span>
          <br />
          <span className="heading-sub">
            implementing measures to reduce or eliminate these risks & providing essential training for the individuals involved.
          </span>
        </h1>
        <ContactInfo />
        <Engineers />
      </div>
    </div>
  );
}
