import React from 'react';
import './CARD.css';

export default function SScard({ title, price }) {
    const formatPrice = (price) => {
        return price % 1 === 0 ? price.toFixed(0) : price.toFixed(2);
    };

    const screenWidth = window.innerWidth;

    const cardBodyStyle = {
        width: screenWidth <= 600 ? '300px' : '650px',
        maxWidth: '100%',
    };

    const titleStyle = {
        textAlign: 'center',
        fontWeight: '700',
        fontSize: screenWidth <= 600 ? '' : 'xxx-large',
    };

    const priceStyle = {
        fontWeight: 'bold',
        fontSize: screenWidth <= 600 ? '24px' : '42px',
        marginBottom: '10px',
    };

    return (
        <div className="card-container card-responsive" style={cardBodyStyle}>
            <div className="card shadow-lg">
                <div className="card-body">
                    <div className="card-img-top">
                        <h3 className="text-shadow-custom" style={titleStyle}>{title}</h3>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={priceStyle}>
                            &#163; {formatPrice(price)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
