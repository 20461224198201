import React, { useEffect } from 'react';
import Navbar from './Components/navbar';
import './App.css';

const LayoutNav = ({ children }) => {
    useEffect(() => {
        const handleScroll = () => {
            const progressBar = document.querySelector('.progress-bar');
            const scrollPercentage = (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100;
            progressBar.style.width = `${scrollPercentage}%`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className="progress-bar" style={{ zIndex: 101 }}></div>
            <Navbar className="header" />
            <main style={{ marginTop: "80px" }}>{children}</main>
        </div>
    );
};

export default LayoutNav;
