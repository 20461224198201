import React from 'react';
import bookingimg from '../../Images/Homepage/bookingimg.png';
import '../../Styles/BookingSection.css';

export default function BookingSection() {
    return (
        <div className="booking-section">
            <div>
                <h1 className="text-center main-title text-shadow-custom" >Secure Your</h1>
                <h1 className="text-center main-title mb-4 mt-1 text-shadow-custom" >Property to Next Level</h1>
            </div>
            <div className="d-flex booking-container booking-b mb-5">
                <div className="m-5 booking-content">
                    <h1 className="booking-title text-shadow-custom" >Book An</h1>
                    <h1 className="booking-title text-shadow-custom mt-3" >Appointment</h1>
                    <p className="booking-description text-shadow-custom mt-4">
                        Stay compliant and put safety first with our professional advice on selecting the right safety certificates for your property. Our dedicated team is here to help you schedule CP12, EPC, EICR, or FRA assessments, ensuring you meet legal obligations and keep your premises secure.
                    </p>
                    <a href="/bookS" className="btn btn-light booking-button mt-3 text-shadow-custom">
                        Book Now &nbsp;<strong>&#10148;</strong>
                    </a>
                </div>
                <div className="booking-image-container">
                    <img src={bookingimg} alt="Booking" className="booking-image" />
                </div>
            </div>
        </div>
    )
}
