import React from 'react'
import Fire from '../../Images/Homepage/Fire.png';
import Gas from '../../Images/Homepage/gas.jpg';
import Electrical from '../../Images/Homepage/electrical.jpg';
import Energy from '../../Images/Homepage/energy.jpg';
import Inventory from '../../Images/Homepage/inventory.jpg';
import HomePageCard from './HomePageCard';

export default function OurServices() {
    const cardsData = [
        { image: Fire, title: "Fire Risk Assessment Certificate", price: 78.99, Link: "/FRA" },
        { image: Gas, title: "Gas Safety Certificate", price: 28.99, Link: "/GSC" },
        { image: Electrical, title: "Electrical Safety Certificate", price: 57.99, Link: "/ESC" },
        { image: Energy, title: "Energy Performance Certificates", price: 69.99, Link: "/EPC" },
        { image: Inventory, title: "Inventory Service", price: 99.99, Link: "/InventoryService" }
    ];
    return (
        <div>
            <div className="container">
                <div className="service-container">
                    <h1 className="text-center service-h text-shadow-custom">Our Services</h1>
                    <p className="text-center service-p">
                        We provide expert EPC, EICR, Gas Safety Certificates, and Fire Risk Assessments for both commercial and residential properties across the UK. Our services ensure that you meet all legal requirements and maintain compliance.
                    </p>
                </div>
                <div className="container service-b">
                    <div className="card-wrapper" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        {cardsData.map((card, index) => (
                            <HomePageCard key={index} imageSrc={card.image} title={card.title} price={card.price} Link={card.Link} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
