import React from 'react';
import SCard from './Card/SCard';

export default function ASc() {
    const cardsData = [
        { title: "Asbestos Surveys Report", price: 294.69, link: "/bookS" }
    ];
    return (
        <div className="container m-3">
            <div className="service-container">
                <h1 className="text-center service-h text-shadow-custom">Asbestos Surveys</h1>
            </div>
            <div className="card-wrapper" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                {cardsData.map((card, index) => (
                    <SCard key={index} title={card.title} price={card.price} link={card.link} />
                ))}
            </div>
        </div>
    )
}
