// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../Images/Navbar/logo.svg';
import PASCL from '../Images/Navbar/PASCL.svg';
import '../Styles/navbar.css';

export default function Navbar() {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // const Cart = JSON.parse(localStorage.getItem('cart'));



    const handleToggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <nav className="navbar navbar-expand-lg fixed-top">
            <div className="container container-navbar">
                <a href="/">
                    <img src={logo} alt="LOGO" width="70" height="auto" />
                    <img src={PASCL} alt="PASCL" width="80" height="auto" />
                </a>
                <button className="navbar-toggler" type="button" onClick={handleToggleNavbar}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse justify-content-center ${isNavbarOpen ? 'show' : ''}`}>
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <NavLink exact to="/" className="nav-link" activeClassName="active" onClick={handleToggleNavbar}>Home</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/Services" role="button" data-bs-toggle="dropdown" aria-expanded={isDropdownOpen} onClick={handleToggleDropdown}>
                                Services
                            </a>
                            <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                                <li><NavLink exact to="/FRA" className="dropdown-item" onClick={handleToggleNavbar}>Fire Risk Assessment Certificate</NavLink></li>
                                <li><NavLink exact to="/GSC" className="dropdown-item" onClick={handleToggleNavbar}>Gas Safety Certificate</NavLink></li>
                                <li><NavLink exact to="/ESC" className="dropdown-item" onClick={handleToggleNavbar}>Electrical Safety Certificate</NavLink></li>
                                <li><NavLink exact to="/EPC" className="dropdown-item" onClick={handleToggleNavbar}>Energy Performance Certificates</NavLink></li>
                                <li><NavLink exact to="/InventoryService" className="dropdown-item" onClick={handleToggleNavbar}>Inventory Services</NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink exact to="/bookS" className="nav-link" activeClassName="active" onClick={handleToggleNavbar}>Book Services</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact to="/about" className="nav-link" activeClassName="active" onClick={handleToggleNavbar}>About Us</NavLink>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="tel:+442081455358">
                                <span className="custom-button">&#9990; 02081455358</span>
                            </a>
                        </li>
                    </ul>
                    {/* {Cart && Cart.cart_items === 0 ? (
                        <>
                        </>
                    ) : (
                        <div className="cart-icon">
                            <NavLink exact to="/cart" className="nav-link" activeClassName="active">
                                <i className="fas fa-shopping-cart"></i>
                                <span>{Cart.cart_items}</span>
                            </NavLink>
                        </div>
                    )
                    } */}
                </div>
            </div>
        </nav>
    );
}
