import React from 'react';
import '../Styles/AboutUs.css';
import Engineers from '../Components/HomePage/Engineers';
import AboutHero from '../Images/AboutHero.png';
import ContactInfo from '../Components/Services/ContactInfo';

export default function AboutUs() {
    return (
        <div>
            <div>
                <div className="about-section">
                    <div className="container">
                        <h1 className="text-center about-h text-shadow-custom">Trust Our Services for Your Safety Needs</h1>
                        <div className="d-flex align-content-between">
                            <div>
                                <p className="text-center about-p">
                                    At PASCL, we are dedicated to providing reliable safety certification services for homeowners and businesses across London and nearby areas. We understand the critical importance of adhering to safety regulations concerning fire, gas, and electrical systems in your property. As a leading provider of safety certificates in London, we prioritize the safety and well-being of your premises.
                                </p><br />
                                <p className="text-center about-p">
                                    Our expertise spans a wide range of safety assessments and certifications, including Fire Risk Assessments, EICRs (Electrical Installation Condition Reports), EPCs (Energy Performance Certificates), and Gas Safety Certifications. We are committed to ensuring your residential or commercial property meets all necessary safety and compliance standards.
                                </p><br />
                                <p className="text-center about-p">
                                    We also offer ongoing support to help you stay compliant with safety requirements. Your peace of mind and safety are our top priorities, and we are here to support you at every stage.
                                </p><br />
                            </div>
                            <div>
                                <img src={AboutHero} alt="About Hero" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                </div>
                <div className="container">
                    <h1 className="text-center about-h mt-3">
                        <span className="heading-main text-shadow-custom">Dedicated to Quality and Efficiency in Safety Services</span>
                        <br />
                        <span className="heading-sub">
                            We're committed to partnering with landlords to keep your properties safe with our thorough safety inspections. PASCL stands for safety and continuous improvement, aiming to provide the best possible services. Your satisfaction is our ultimate goal.
                        </span>
                    </h1>
                    <ContactInfo />
                    <Engineers />
                </div>
            </div>
        </div>
    )
}
