import React from 'react';
import '../Styles/homepage.css';
import HeroSection from '../Components/HomePage/HeroSection.jsx';
import Engineers from '../Components/HomePage/Engineers.jsx';
import OurServices from '../Components/HomePage/OurServices.jsx';
import BookingSection from '../Components/HomePage/BookingSection.jsx';

export default function Homepage() {
  return (
    <div>
      {/* Hero section with background color */}
      <HeroSection />
      {/* Services section with background image */}
      <OurServices />
      {/* Booking section */}
      <BookingSection />
      {/* Engineers Section */}
      <Engineers />
    </div>
  );
}
