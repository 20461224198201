import React from 'react';

export default function ContactInfo() {
    return (
        <div className="booking-pcontact card-body shadow-lg mb-2">
            <div className="container d-flex align-items-center flex-column" style={{ maxWidth: '430px', margin: 'auto' }}>
                <p><strong>For Instant Quote and Booking Call Us</strong></p>
                <p>Our professionals will assist you in a friendly way.</p>
                <h1 className="booking-ph1"><a className="booking-pphone text-shadow-custom" href="tel:+442081455358"> &#9990; 020 8145 5358</a></h1>
                <p className="booking-ptext-strong text-shadow-custom"><strong>For Further Information Contact Via</strong></p>
                <a href="mailto:info@pascl.co.uk" className="mail-link text-shadow-custom" title="Send an email to info@pascl.co.uk">
                    <span>&#9993;Info&#64;pascl.co.uk</span>
                </a>
            </div>
        </div>
    )
}
